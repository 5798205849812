@keyframes TitleAnimation {
  0% {
    transform: rotateX(90deg) scaleY(0);
    color: $color-black;
  }
  100% {
    transform: rotateX(0) scaleY(1);
    color: $color-primary;
  }
}

@keyframes SlideDown {
  0% {
    transform: translateY(-100px) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
}

@keyframes SlideUpOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes FadeIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.slide-down {
  animation-name: SlideDown;
  animation-timing-function: ease;
  animation-duration: 300ms;
  transform-origin: center;
}

.slide-up-out {
  animation-name: SlideUpOut;
  animation-timing-function: ease;
  animation-duration: 300ms;
  transform-origin: center;
}

.title-animation {
  animation-name: TitleAnimation;
  animation-timing-function: ease;
  animation-duration: 2s;
  transform-origin: center;
}

.fade-in {
  animation-name: FadeIn;
  animation-timing-function: ease;
  animation-duration: 1s;
  transform-origin: center;
}
