.app-title {
  padding: $padding-default;
  position: relative;
  user-select: none;
  font-weight: bolder;
  letter-spacing: 10px;
  text-align: center;
  font-size: calc(14px + 2vmin);
}

p {
  font-size: 1.5rem;
  text-align: justify;
  line-height: 3rem;
}

.game-status-emoticon {
  font-size: 2rem;
}

.text-monospaced {
  font-family: $font-family;
}
