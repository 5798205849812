$font-family: "IBM Plex Sans";

// colors
$color-primary: var(--app-primary-color);
$color-primary-light: var(--app-primary-color-light);
$color-primary-lighter: var(--app-primary-color-lighter);
$color-primary-lightest: var(--app-primary-color-lightest);
$color-white: #fff;
$color-black: #000;

// typography
$font-size-normal: 1rem !default;
$font-size-large: 1.3rem !default;

// paddings
$padding-default: 1rem !default;

// border-radius
$border-radius-default: 8px !default;
$border-radius-large: 15px !default;
$border-radius-largest: 30px !default;
