.card {
  width: calc(20px + 8vmin);
  height: calc(20px + 8vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(20px + 2vmin);
  font-weight: 700;
  user-select: none;
  pointer-events: none;
  position: relative;
  transition: all 500ms ease;
  border-radius: $border-radius-large;
  font-family: $font-family;

  &::after {
    content: "";
    border-radius: $border-radius-large;
    @include OverlayLayer();
  }

  &::before {
    content: "";
    border-radius: $border-radius-large;
    @include OverlayLayer();
  }

  &-hidden {
    background-color: $color-primary;
    pointer-events: all;
    transition: all 500ms ease;

    &:after {
      transform: rotateY(0);
    }

    &:before {
      transform: rotateY(0);
    }

    > .card-text {
      display: none;
      opacity: 0;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 6px 6px 0 0 $color-primary-light;
      background-color: $color-primary-light;
    }
  }
}

.board-disabled {
  .card {
    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    width: calc(20px + 10vmin);
    height: calc(20px + 10vmin);
  }
}
