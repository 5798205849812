// $grid-col-size: calc(10px + 6vmin);

.board {
  display: grid;
  gap: 16px;
  max-height: 70vh;
  max-width: 95vw;

  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);

  //  Change  values 👇  to experiment
  align-content: center;
  padding: $padding-default;
}

@media screen and (max-width: 768px) {
  .board {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(9, 1fr);
  }
}

@media screen and (max-width: 549px) {
  .board {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(9, 1fr);
  }
}
