html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--app-primary-color-lightest);
  width: 100vw;
  min-height: 100vh;
  color: $color-primary;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: $font-family;
}

a {
  text-decoration: none;
  color: $color-primary;
  background-color: $color-primary;
  transition: all 500ms ease;

  &:hover {
    background-color: $color-primary-lighter;
  }
}
