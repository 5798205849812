.btn {
  outline: none;
  border: none;
  border-radius: $border-radius-largest;
  font-family: $font-family;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: $color-primary-lighter;
  color: $color-primary;
  padding: $padding-default;
  margin: 1rem auto;

  &::after {
    content: "";
    border-radius: $border-radius-largest;
    @include OverlayLayer();
  }

  &::before {
    content: "";
    border-radius: $border-radius-largest;
    @include OverlayLayer();
  }

  &:hover {
    color: $color-primary;
    transition: all 500ms ease;

    &:after {
      transform: rotateY(0);
    }

    &:before {
      transform: rotateY(0);
    }
  }

  &-link {
    text-decoration: none;
    background-color: transparent;
  }

  &-lg {
    font-size: 1.4rem;
    background-color: $color-primary;
    color: $color-white;

    &:hover {
      background-color: transparent;
    }
  }

  &-block {
    width: 100%;
  }
}
