.greetings {
  width: 40vw;
  padding: 1rem;
  background-color: $color-primary-lighter;
  border-radius: $border-radius-default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .greetings {
    width: 80vw;
  }
}
