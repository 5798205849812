@mixin OverlayLayer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $color-primary-lighter;
  top: 0;
  left: 0;
  transition: all 300ms ease;
  transform: rotateY(90deg);
  transform-origin: bottom center;
}
