.social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 50px;
  list-style: none;
  padding: 0 1rem;
  margin-top: 3rem;

  li {
    margin: 0.2rem 0.4rem;
  }
}
